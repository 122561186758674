<template>
  <!-- Error page-->
  <div class="misc-wrapper" style="background-color: #f8fbfc !important">
    <b-link class="brand-logo">
      <protex-logo style="width: 150px !important; height: auto" />
    </b-link>
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <img
          :src="coneImg"
          style="width: 300px; height: 300px; margin-bottom: 16px"
        />
        <h2
          class="mb-1"
          style="color: #052d41; font-weight: 600; font-size: 32px"
        >
          Oops.. looks like you're lost
        </h2>
        <p class="mb-2" style="color: #596d80; font-size: 16px">
          The page you are looking for doesn't exist
        </p>
        <center>
          <b-button
            variant="primary"
            style="
              height: 56px;
              font-weight: 600;
              font-size: 18px;
              line-height: 150%;
              width: 224px;
              margin-top: 32px;
            "
            class="mb-2 btn-sm-block"
            :to="{ path: '/' }"
          >
            Back to home
          </b-button>
        </center>
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from "bootstrap-vue";
import store from "@/store/index";
import ProtexLogo from "@core/layouts/components/Logo.vue";

export default {
  components: {
    BLink,
    BButton,
    BImg,
    ProtexLogo,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/error.svg"),
      coneImg: "./cone_img.png",
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/error-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";

#userback_button_container {
  display: none !important;
}

#crisp-chatbox {
  visibility: hidden !important;
}

#crisp-chatbox > div > a > span.cc-7doi.cc-1ada > span > span.cc-15mo {
  visibility: hidden !important;
}
#crisp-chatbox > div > a > span > span > span{
  visibility: hidden !important;
}
</style>
